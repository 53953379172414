import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LoginService } from '../../services/login.service';
import { MicrositeService } from 'src/app/services/microsite.service';
import { FacebookService } from '../../services/facebook.service';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { PushService } from '../../services/push.service';
import { GoogleService } from '../../services/google.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  @Input() redirectUrl: string;

  nombre: string;

  private usuariosocial = {
    user_social: null,
    user_agent: null,
    client_ip: null,
    email_confirmation: false,
  }

  private loading: any;

  usuario = {
    email: '',
    password: '',
    user_agent: null,
    client_ip: null
  };

  error = {
    email: null,
    password: null,
  };
  user_msg: string = "";

  resultLogin: string;
  errform: any;
  buttonLoading: boolean = false;


  constructor(
    private loginService: LoginService,
    private facebookService: FacebookService,
    private googleService: GoogleService,
    private micrositeService: MicrositeService,
    private router: Router,
    public helper: GlobalFunctionsHelper,
    private aroute: ActivatedRoute,
    private pushService: PushService,
  ) { }

  ngOnInit() {
    // this.loginService.getStorageLoginNotificaciones();
    this.closeSession();
  }

  // login con bookersnap
  async onSubmitLogin() {

    this.helper.presentLoading({ message: 'Iniciando...' }); // loading

    this.buttonLoading = true;
    this.usuario.user_agent = // await this.helper.userAgentRes(); // obtener user agente
    this.usuario.client_ip = await this.helper.networkInterfaceRes(); // obtener ip

    this.loginService.setLoginBookersnap(this.usuario).subscribe(resp => {
      this.responseDataSuccess(resp);
      this.helper.dismissLoading(); // cerrar loading
      this.buttonLoading = false;

    }, (error) => {
      this.helper.dismissLoading(); // cerrar loading
      this.responseDataError(error);
      this.buttonLoading = false;

    });
  }

  // login con facebook
  onFacebookLogin() {

    this.helper.presentLoading({ message: 'Iniciando...' }); // loading
    this.facebookService.loginFacebook().then(async (resolveOutput) => {
      this.usuariosocial.user_social = resolveOutput;

      this.usuariosocial.user_agent = // await this.helper.userAgentRes(); // obtener user agente
      this.usuariosocial.client_ip = await this.helper.networkInterfaceRes(); // obtener ip

      this.loginService.setLoginSocial(this.usuariosocial).subscribe(resp => {
        console.log(resp);
        this.responseDataSuccess(resp);
        this.helper.dismissLoading(); // cerrar loading
      }, error => {
        console.log(error);
        this.helper.dismissLoading(); // cerrar loading
        this.responseDataError(error);
        this.buttonLoading = false;
      });

    }, (rejectOutput) => {
      setTimeout(() => { this.helper.dismissLoading(); }, 1000);
      // cerrar loading
      console.log(rejectOutput);
    });

  }

  // login con google
  onGoogleLogin() {

    this.helper.presentLoading({ message: 'Iniciando...' }); // loading
    this.googleService.loginGoogle().then(async (resolveOutput) => {
      this.usuariosocial.user_social = resolveOutput;

      this.usuariosocial.user_agent = // await this.helper.userAgentRes(); // obtener user agente
      this.usuariosocial.client_ip = await this.helper.networkInterfaceRes(); // obtener ip

      this.loginService.setLoginSocial(this.usuariosocial).subscribe(resp => {
        console.log(resp);
        this.responseDataSuccess(resp);
        this.helper.dismissLoading(); // cerrar loading
      }, error => {
        console.log(error);
        this.helper.dismissLoading(); // cerrar loading
        this.responseDataError(error);
        this.buttonLoading = false;
      });

    }, (rejectOutput) => {
      console.log(rejectOutput);
      return this.helper.dismissLoading();
      // this.helper.dismissLoading(); // cerrar loading
    });
  }

  async closeSession() {

    let clo = await this.aroute.snapshot.params.paramcustom;
    let sto = await this.loginService.getStorageLogin();
    if (clo === 'closesession' && sto) {
      const onesignal_userid = await this.pushService.getaOneSignalGetIds();
      console.log("eliminado temporales", onesignal_userid);
      await this.loginService.closeSessiones(false, onesignal_userid); // cerrar sesion
    }

  }

  // response exitosa al login
  private responseDataSuccess(resp: any) {

    let numR = [];
    numR = this.micrositeService.listMicro(resp.data);
    console.log(resp.data);
    console.log(numR);
    if (numR.length > 1) {
      this.pushService.getDeviceUserIds(); // registrar device id
      this.loginService.setStorateLogin(resp.data);
      this.router.navigateByUrl(this.redirectUrl); // redirect
    } else if (numR.length === 1) {
      this.pushService.getDeviceUserIds(); // registrar device id
      this.loginService.setStorateLogin(resp.data);
      this.router.navigateByUrl(`/home/${numR[0].ms_microsite_id}/search-availability`); // redirect
    } else {

      this.loginService.setStorateLogin(resp.data);
      this.router.navigateByUrl(`/rp/check-cod`); // redirect

    }
  };

  // response error al login
  private responseDataError(error) {
    this.errform = error.error.error;
    if (this.errform.errors != null) {
      this.error.email = (this.errform.errors.email) ? this.errform.errors.email : null;
      this.error.password = (this.errform.errors.password) ? this.errform.errors.password : null;
    }

    this.user_msg = this.errform.user_msg;
  }

}
