import { Component, OnInit, ApplicationRef } from '@angular/core';
import { PushService } from '../../services/push.service';
// import { OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { OSNotificationPayload } from '@awesome-cordova-plugins/onesignal/ngx';
import { LoginService } from '../../services/login.service';
import { MesasService } from '../../services/mesas.service';
import { environment } from '../../../environments/environment';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  // mensajes: OSNotificationPayload[] = [];
  mensajes: any[] = [];
  statusInfo = false;

  constructor(
    private pushService: PushService,
    private applicationRef: ApplicationRef,
    private loginService: LoginService,
    private mesasService: MesasService,
    private helper: GlobalFunctionsHelper,
    private router: Router,
  ) { }

  ngOnInit() {

    this.iniciando();

  }

  async iniciando(){
    await this.mesasService.iniciando({Authorization: ''}); // colocando token
    this.mesasService.getNotificationReservationPushRp().subscribe(resp => {
                        this.mensajes = resp.data;
                        if (!this.mensajes.length) {
                          this.statusInfo = true;
                        }
                        this.applicationRef.tick();
                      }, error => {
                        console.log(error);
                      });

  }

  async doRefresh(event){
    this.statusInfo = false;
    await this.mesasService.iniciando({Authorization: ''}); // colocando token
    this.mesasService.getNotificationReservationPushRp().subscribe(resp => {
                        this.mensajes = resp.data;
                        if (!this.mensajes.length) {
                          this.statusInfo = true;
                        }
                        this.applicationRef.tick();
                        event.target.complete();
                      }, error => {
                        console.log(error);
                      });
  }

  imgsrc(img){
    return this.helper.imgFavicon160x160(img);
  }

  redirectLink(res_reservation_id: string){
    return '/history?microsite=all&reservation_id=' + res_reservation_id;
  }

}
