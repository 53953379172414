import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-histories-popover-info',
  templateUrl: './histories-popover-info.component.html',
  styleUrls: ['./histories-popover-info.component.scss'],
})
export class HistoriesPopoverInfoComponent implements OnInit {

  @Input() data: any;
  @Input() type: any;

  constructor() { }

  ngOnInit() {


  }

}
