import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

const lang = environment.lang;

@Component({
  selector: 'app-reserve-availability',
  templateUrl: './reserve-availability.component.html',
  styleUrls: ['./reserve-availability.component.scss'],
})
export class ReserveAvailabilityComponent implements OnInit {

  @Input() responsesearch: any;
  loading: any;
  datetime: string;
  //selOption: any;

  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
  ) { }

  ngOnInit() {
    // console.log(' wwwwwwwww ');
    // console.log(this.responsesearch);
    // tslint:disable-next-line: max-line-length
    this.datetime = moment(`${this.responsesearch.date} ${this.responsesearch.hour}`).locale(lang[0]).format('dddd, D [de] MMM YYYY [a las] h:mm A');
  }

  selectAvailability(selOption: any) {
    this.modalCtrl.dismiss(selOption);
  }

  salirSinArgumentos() {
    this.modalCtrl.dismiss(null);
  }

  async presentLoading(mensaje: string) {
    this.loading = await this.loadingCtrl.create({
      message: mensaje,
      //duration: 2000
    });
    return this.loading.present();
  }

  fun_hora(data) {
    return (data) ? moment(data).locale(lang[0]).format('h:mm A') : '';
  }

}
