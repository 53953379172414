import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from '../../services/login.service';
import { MicrositeService } from '../../services/microsite.service';
import { environment } from '../../../environments/environment';
import { AlertController } from '@ionic/angular';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { Router, NavigationEnd } from '@angular/router';

const domainMain = environment.domainMain;

@Component({
  selector: 'app-micrositios',
  templateUrl: './micrositios.component.html',
  styleUrls: ['./micrositios.component.scss'],
})
export class MicrositiosComponent implements OnInit {

  //componentes: Observable<ResponseLogin[]>;
  componentes: any;
  domainmain;
  private subscription: Subscription;

  constructor(
    private loginService: LoginService,
    private micrositeService: MicrositeService,
    public alertController: AlertController,
    private helper: GlobalFunctionsHelper,
    private applicationRef: ApplicationRef,
    private router: Router,
  ) { }

  async ngOnInit() {
    // this.componentes = null;
    // this.iniciando();
    await this.onEnter();

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/list-micrositios') {
        this.onEnter();
      }
    });
  }

  iniciando() {
    this.domainmain = domainMain;

    let result = this.loginService.getStorageLogin()
      .then((resp) => {
        let numR = [];
        numR = this.micrositeService.listMicro(resp);
        this.componentes = numR;
        this.applicationRef.tick();
        if (numR.length === 0) {
          this.presentAlert(resp.user.firstname + ' ' + resp.user.lastname, resp.user.email); // alert
        } else if (numR.length === 1) {
          this.router.navigateByUrl(`/home/${numR[0].ms_microsite_id}/search-availability`); // redirect
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  public async onEnter(): Promise<void> {
    this.domainmain = domainMain;

    let result = this.loginService.getStorageLogin()
      .then((resp) => {
        let numR = [];
        numR = this.micrositeService.listMicro(resp);
        this.componentes = numR;
        this.applicationRef.tick();
        if (numR.length === 0) {
          this.presentAlert(resp.user.firstname + ' ' + resp.user.lastname, resp.user.email); // alert
        } else if (numR.length === 1) {
          this.router.navigateByUrl(`/home/${numR[0].ms_microsite_id}/search-availability`); // redirect
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async presentAlert(name: string, email: string) {
    // tslint:disable-next-line: max-line-length
    this.helper.presentAlertRedirect(
      name + ' - ' + email,
      'No tienes permisos para acceder a la aplicacíon. Solicite permisos al administrador de la aplicación.',
      '/login-inicio'
    );
  }

}
