import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  listmenu: any[] = [];
  ms_microsite_id: string;

  private subscription: Subscription;

  constructor(
    private aroute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

    // iniciar un subcribe para obtener el id de micrositio de la url
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart && event.snapshot.params.ms_microsite_id != null) {
        this.ms_microsite_id = event.snapshot.params.ms_microsite_id;
      }
    });

  }

  /*
    ejecutar esta funcion cuando se de click en menu e inyectar el id del micrositio para actualizar la lista
  */
  onMenuOpen() {
    this.listaMenu(this.ms_microsite_id);
  }

  // tslint:disable-next-line: variable-name
  listaMenu(ms_microsite_id: string) {
    this.listmenu = [
                      {
                        icon: 'home',
                        name: 'Principal',
                        redirectTo: `/home/${ms_microsite_id}`,
                        funcionMenu: null,
                      },
                      {
                        icon: 'storefront',
                        name: 'Cambiar de sitio',
                        redirectTo: `/list-micrositios`,
                        funcionMenu: null,
                      },
                      {
                        icon: 'scan',
                        name: 'Registrar RP',
                        redirectTo: `/rp/check-cod`,
                        funcionMenu: null,
                      },
                      {
                        icon: 'happy',
                        name: 'Crear reserva',
                        redirectTo: `/home/${ms_microsite_id}/search-availability`,
                        funcionMenu: null,
                      },
                      {
                        icon: 'clipboard',
                        name: 'Historial de reservas',
                        redirectTo: `/history`,
                        funcionMenu: null,
                      },
                      {
                        icon: 'power',
                        name: 'Cerrar sesion',
                        redirectTo: '/login-inicio/closesession',
                        funcionMenu: null,
                      }
                    ];
  }

  closeSesion() {
     console.log('cerrando sesioneee');
  }

  async paramsRoute() {

  }

}
