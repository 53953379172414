import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reserve-more-info',
  templateUrl: './reserve-more-info.component.html',
  styleUrls: ['./reserve-more-info.component.scss'],
})
export class ReserveMoreInfoComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  salirSinArgumentos(){

    this.modalCtrl.dismiss();

  }

}
