import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';

import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
// import { UserAgent } from '@ionic-native/user-agent/ngx';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';

import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { NgCalendarModule } from 'ionic2-calendar';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AuthGuardService } from './services/auth-guard.service';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
// import { ZBar, ZBarOptions } from '@awesome-cordova-plugins/zbar/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    NgCalendarModule
  ],
  providers: [
    // StatusBar,
    SplashScreen,
    NetworkInterface,
    Facebook,
    GooglePlus,
    OneSignal,
    AppVersion,
    Clipboard,
    Camera,
    // ZBar,
    BarcodeScanner,
    AuthGuardService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
