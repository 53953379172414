import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { environment } from 'src/environments/environment';
import { ResponseFormatAvailability } from '../interfaces/format-availability.interfaces';
import { Responsedaysdisabled } from '../interfaces/daysdisabled';
import { ResponseReservationTemporal } from '../interfaces/reservation-temporal.interfaces';
import { LoginService } from './login.service';
import { ResponseFormReservation } from '../interfaces/form-reservation.interfaces';
import { ResponseConfirmarReservation } from '../interfaces/confirmar-reservation.interfaces';
import { ResponsePush } from '../interfaces/push.interfaces';
import { ResponseHistory } from '../interfaces/history.interface';

const apiWebAdmin = environment.apiWebAdmin;
const apiMesas = environment.apiMesas;
const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  // 'Content-Type': 'application/x-www-form-urlencoded'
  // 'Content-Length': '0'
  // 'Authorization': "Bearer c8B6wbwadT3Bq6PmN8sURCDqEdzG6sEn"
});


@Injectable({
  providedIn: 'root'
})
export class MesasService {
  
  headerTemp: any;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private loginService: LoginService,
  ) { }
  
  //iniciando headers
  async iniciando(param){
    param.Authorization = `Bearer ${(await this.loginService.getStorageLogin()).token_session}`;
    this.headerTemp = new HttpHeaders(param);
  }

  iniciandoLibre(param){
    this.headerTemp = new HttpHeaders(param);
  }

  // obtener informacion para el formulario de buscar disponibilidad
  getFormatAvailability(ms_microsite_id: string, date?: string){

    let query = '';
    if(date){
      query = `?date=${date}`;
    }

    // tslint:disable-next-line: max-line-length
    return this.http.get<ResponseFormatAvailability>(`${apiWebAdmin}/w/${ms_microsite_id}/api/availability/formatAvailability${query}`, { headers });
  }

  // crear temporal al elegir una disponibilidad
  postReservationTemporal(ms_microsite_id: string, params){
    return this.http.post<ResponseReservationTemporal>(`${apiWebAdmin}/w/${ms_microsite_id}/api/reservationtemporal`, params, {headers: headers});
  }

  // obtener fechas deshabilitadas para el calendario
  getDaysSisabled(ms_microsite_id: string, date_ini: string, date_fin: string){
    return this.http.get<Responsedaysdisabled>(`${apiWebAdmin}/w/${ms_microsite_id}/api/availability/daysdisabled?date_fin=${date_fin}&date_ini=${date_ini}`, { headers });
  }

  // buscar disponibilidad
  getSearchAvailability(ms_microsite_id: string, date: string, num_guests: string, hour: string, zone_id?: string){
    return this.http.get<Responsedaysdisabled>(`${apiWebAdmin}/w/${ms_microsite_id}/api/availability/basic?date=${date}&hour=${hour}&num_guests=${num_guests}&zone_id=${zone_id}`, { headers });
  }
  
  // crear reservacion
  postReservation(ms_microsite_id: string, params){
    return this.http.post<ResponseFormReservation>(`${apiMesas}/microsites/${ms_microsite_id}/table/reservation/rpsweb`, params, {headers: this.headerTemp});
  }

  // cancelar reservacion
  postCancelReservation(ms_microsite_id: string, token: string, params?){
    return this.http.post<ResponseConfirmarReservation>(`${apiWebAdmin}/w/${ms_microsite_id}/api/table/reservation/cancel/${token}`, params, {headers: this.headerTemp});
  }

  // confirmar reservacion
  getConfirmarReservation(ms_microsite_id: string, token: string, params?){
    return this.http.get<any>(`${apiMesas}/microsites/${ms_microsite_id}/table/reservation/confirmed/${token}`, { headers: this.headerTemp});
  }

  // obtener reserva temporal
  getReservationTemporal(ms_microsite_id: string, token: string, params?){
    return this.http.get<any>(`${apiMesas}/microsites/${ms_microsite_id}/reservationtemporal/${token}`, { headers: this.headerTemp});
  }

  // obtener las ultimas 20 notificaciones
  getNotificationReservationPushRp(params?){
    // tslint:disable-next-line: max-line-length
    return this.http.get<ResponsePush>(`${apiMesas}/notifications/push`, { headers: this.headerTemp});
  }

  // obtener el historial de reservas del rp
  // tslint:disable-next-line: variable-name
  getHistory(microsite: string, reservation_id: string = '', date_reservation: string = '', hours_reservation: string = '', name: string = '', datetime: number = 0, datecalendar: string){
    return this.http.get<ResponseHistory>(`${apiMesas}/microsites/${microsite}/table/rpsweb/history?reservation_id=${reservation_id}&date_reservation=${date_reservation}&hours_reservation=${hours_reservation}&name=${name}&datetime=${datetime}&datecalendar=${datecalendar}`, { headers: this.headerTemp});
  }

  patchReservationHistoryCancel(ms_microsite_id: string, bs_user_id: string, res_reservation_id: string, params?){
    return this.http.patch<any>(`${apiMesas}/microsites/${ms_microsite_id}/table/rpsweb/${bs_user_id}/reservation/${res_reservation_id}`, params, {headers: this.headerTemp});
  }

  countrySelect() {
    let countrySelect = {
          country: []
        };
    countrySelect.country.push({
                                cod: 'MX',
                                name: 'México',
                                cod_phone: '+52',
                                zone_time: 'America/Mexico_City',
                                img_bandera: 'icon_mexico'
                              });

    countrySelect.country.push({
                                cod: 'PE',
                                name: 'Perú',
                                cod_phone: '+51',
                                zone_time: 'America/Lima',
                                img_bandera: 'icon_peru.jpg'
                              });
    
    return countrySelect;
  }

  filterHistoryReserve(){
    let filter = [];
    filter.push({
                  value: 0,
                  text: 'Todos',
                });
    filter.push({
                  value: 1,
                  text: 'Hoy',
                });
    filter.push({
                  value: 2,
                  text: 'Ultimos 7 días',
                });
    filter.push({
                  value: 3,
                  text: 'Ultimos 30 días',
                });
    filter.push({
                  value: 4,
                  text: 'Este año',
                });
    
    return filter;
  }

}
