import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarComponent } from 'ionic2-calendar/calendar';
import * as moment from 'moment';

import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';

@Component({
  selector: 'app-histories-filter',
  templateUrl: './histories-filter.component.html',
  styleUrls: ['./histories-filter.component.scss'],
})
export class HistoriesFilterComponent implements OnInit {

  @Input() filters: any[] = [];
  @Input() checkdefault: any;
  @Input() datecalendar: string;
  @Output() selectFilter = new EventEmitter<any>();

  @ViewChild(CalendarComponent) myCalendar: CalendarComponent;

  nMonth: string; // numero del año-mes

  calendar: any = {
    mode: 'month',
    currentDate: null,
    locale: 'es-AR'
  }
  showEventDetail: any = false;

  viewTitle;
  startTime: any;
  endTime: any;
  iniCalendar: boolean;

  constructor(
    private modalCtrl: ModalController,
    private helper: GlobalFunctionsHelper,
  ) { }

  ngOnInit() {
    // tslint:disable-next-line: max-line-length
    this.calendar.currentDate = (this.datecalendar !== '') ? this.helper.convertFechaToDate(this.datecalendar) : new Date(); // new Date(); // this.helper.convertFechaToDate(this.datehoy); // new Date(`${this.datehoy}T00:00:00`);
    this.nMonth = moment(this.calendar.currentDate).format('YYYY-MM');
    this.iniCalendar = false; // no iniciar calendario
  }

  // cuando se selecciona un filtro de radio button
  onChangeHandler(event){
    console.log(event);
    this.selectFilter.emit({
          value: event,
          type: 'radiobutton'
        });
  }

  // cuando presiona en el boton X
  salirSinArgumentos(){
    this.modalCtrl.dismiss();
  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  // Cuando selecciona una fecha;
  onCurrentDateChanged(date: any) {

    if(this.iniCalendar) {

      let actual = moment(date).format('YYYY-MM');
      let fechaseleccionada = moment(date).format('YYYY-MM-DD');
      if(this.nMonth !== actual){
        this.nMonth = actual;
      } else {
        this.selectFilter.emit({
          value: fechaseleccionada,
          type: 'calendar'
        });
      }

    }

    this.iniCalendar = true; // iniciar calendario
  }

  next(){
    const swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slideNext();
  }

  back(){
    const swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slidePrev();
  }


}
