import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reserve-date-time',
  templateUrl: './reserve-date-time.component.html',
  styleUrls: ['./reserve-date-time.component.scss'],
})
export class ReserveDateTimeComponent implements OnInit {

  @Input() hours: any[] = [];
  @Input() checkdefault: any;
  @Input() reser: string = "";
  @Output() selectHours = new EventEmitter<any>();

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  onChangeHandler( $event: any ){

    const inx = this.hours.findIndex(opt => opt.index === $event);
    this.checkdefault = {
      value: $event,
      string: this.hours[inx].option_user,
      index: inx,
      option: this.hours[inx].option,
    };

    // tslint:disable-next-line: triple-equals
    if (this.reser != 'fast') {
      this.selectHours.emit(this.checkdefault);
    } else {
      this.modalCtrl.dismiss(this.checkdefault);
    }

  }

  salirSinArgumentos(){

    const data = {
      value: this.checkdefault.value,
      string: this.checkdefault.string,
      index: this.checkdefault.index,
      option: this.checkdefault.option
    };

    if (this.reser != 'fast') {
      this.selectHours.emit(data);
    } else {
      this.modalCtrl.dismiss(data);
    }

  }

}
