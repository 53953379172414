import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';

import { HeaderComponent } from './header/header.component';
import { MicrositiosComponent } from './micrositios/micrositios.component';
import { LoginComponent } from './login/login.component';
import { ReserveCalendarComponent } from './reserve-calendar/reserve-calendar.component';
import { ReserveDateTimeComponent } from './reserve-date-time/reserve-date-time.component';
import { ReservePeopleComponent } from './reserve-people/reserve-people.component';

import { NgCalendarModule  } from 'ionic2-calendar';

import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { ReserveZonesComponent } from './reserve-zones/reserve-zones.component';
import { ReserveAvailabilityComponent } from './reserve-availability/reserve-availability.component';
import { FormReserveComponent } from './form-reserve/form-reserve.component';
import { MenuComponent } from './menu/menu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

import { HistoriesComponent } from './histories/histories.component';
import { HistoriesFilterComponent } from './histories-filter/histories-filter.component';
import { HistoriesPopoverInfoComponent } from './histories-popover-info/histories-popover-info.component';
import { FormReserveFastComponent } from './form-reserve-fast/form-reserve-fast.component';
import { ReserveMoreInfoComponent } from './reserve-more-info/reserve-more-info.component';


registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  entryComponents: [
    HistoriesPopoverInfoComponent,
    ReservePeopleComponent,
    ReserveCalendarComponent,
    ReserveDateTimeComponent,
    ReserveMoreInfoComponent,
  ],
  declarations: [
    HeaderComponent,
    MicrositiosComponent,
    LoginComponent,
    ReserveCalendarComponent,
    ReserveDateTimeComponent,
    ReservePeopleComponent,
    ReserveZonesComponent,
    ReserveAvailabilityComponent,
    ReserveMoreInfoComponent,
    FormReserveComponent,
    FormReserveFastComponent,
    MenuComponent,
    NotificationsComponent,
    HistoriesComponent,
    HistoriesFilterComponent,
    HistoriesPopoverInfoComponent,
  ],
  exports:[
    HeaderComponent,
    MicrositiosComponent,
    LoginComponent,
    ReserveCalendarComponent,
    ReserveDateTimeComponent,
    ReservePeopleComponent,
    ReserveZonesComponent,
    ReserveAvailabilityComponent,
    ReserveMoreInfoComponent,
    FormReserveComponent,
    FormReserveFastComponent,
    MenuComponent,
    NotificationsComponent,
    HistoriesComponent,
    HistoriesFilterComponent,
    HistoriesPopoverInfoComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    IonicStorageModule.forRoot(),
    NgCalendarModule,
  ],
  providers: [
    OneSignal,
    { provide: LOCALE_ID, useValue: 'es-AR' }
  ]
})
export class ComponentsModule { }
