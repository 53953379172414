import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() titulo: string;
  @Input() menuActive = false;
  @Input() backActive = false;
  @Input() backUrl: string;
  @Input() notifica = true;

  constructor() { }

  ngOnInit() {
    //console.log(this.backActive);
  }

}
