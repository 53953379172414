import { Component, OnInit, ApplicationRef, EventEmitter, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { MesasService } from '../../services/mesas.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { ModalHistoryPage } from '../../pages/history/modal-history/modal-history.page';
import { ModalController, PopoverController, AlertController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { HistoriesPopoverInfoComponent } from '../histories-popover-info/histories-popover-info.component';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { PushService } from '../../services/push.service';

const lang = environment.lang;

@Component({
  selector: 'app-histories',
  templateUrl: './histories.component.html',
  styleUrls: ['./histories.component.scss'],
})
export class HistoriesComponent implements OnInit, OnChanges {

  // @Input() changeload: any;
  @Input() filtercheck: any;
  @Input() datecalendar: string;
  @Input() selectMicrositeId: string;
  @Input() reservationId: string;
  // @Output() dataHistOutput = new EventEmitter();

  queryString = {
    microsite: '',
    reservation_id: '',
    date_reservation: '',
    hours_reservation: '',
    name: '',
    datetime: null,
    datecalendar: ''
  };

  dataHist: any[] = [];
  dataStat: any[] = [{
    num_people_h: 0,
    num_people_m: 0,
    num_people_n: 0,
    num_people_all: 0
  }];
  statusInfo = false;

  constructor(
    private mesasService: MesasService,
    private aroute: ActivatedRoute,
    private applicationRef: ApplicationRef,
    public popoverController: PopoverController,
    private helper: GlobalFunctionsHelper,
    private pushService: PushService,
    public alertController: AlertController,
  ) { }

  ngOnInit() {
    this.iniciando();

    // carga la información solamente cuando el usuario se encuentra en la misma pantalla y le da click en la notificacion push
    this.pushService.pushListener.subscribe(noti => {
      this.iniciando();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    // tslint:disable-next-line: max-line-length
    if ((changes.filtercheck !== undefined && !changes.filtercheck.firstChange) ||
      (changes.datecalendar !== undefined && !changes.datecalendar.firstChange) ||
      (changes.selectMicrositeId !== undefined && !changes.selectMicrositeId.firstChange)
    ) {

      this.dataHist = [];
      this.queryString.datecalendar = (changes.datecalendar !== undefined) ? changes.datecalendar.currentValue : '';
      if (changes.selectMicrositeId !== undefined && !changes.selectMicrositeId.firstChange) {
        this.queryString.microsite = (parseInt(changes.selectMicrositeId.currentValue)) ? changes.selectMicrositeId.currentValue : 'all';
      } else {
        this.queryString.datetime = (changes.filtercheck !== undefined) ? changes.filtercheck.currentValue : '0';
      }

      // tslint:disable-next-line: radix
      this.getResponse().subscribe(resp => {
        this.dataHist = resp.data.res;
        if (!this.dataHist.length) {
          this.statusInfo = true;
        }
        this.dataStat = resp.data.statistics;
        // tslint:disable-next-line: max-line-length
        this.dataStat[0].num_people_all = this.fun_depie(this.dataStat[0].num_people_h, this.dataStat[0].num_people_m, this.dataStat[0].num_people_n, this.dataStat[0].num_people_all);
        this.queryString.reservation_id = '';
        this.applicationRef.tick();
      }, error => {
        console.log(error);
      });
    }

  }

  async iniciando() {

    // ontener los query strin para enviar al servicio
    this.queryString.microsite = (this.selectMicrositeId) ? this.selectMicrositeId : 'all'; // (this.aroute.snapshot.queryParamMap.get('microsite')) ? this.aroute.snapshot.queryParamMap.get('microsite') : 'all';\
    this.queryString.reservation_id = (this.aroute.snapshot.queryParamMap.get('reservation_id')) ? this.aroute.snapshot.queryParamMap.get('reservation_id') : '';
    this.queryString.date_reservation = (this.aroute.snapshot.queryParamMap.get('date_reservation')) ? this.aroute.snapshot.queryParamMap.get('reservation_id') : '';
    this.queryString.hours_reservation = (this.aroute.snapshot.queryParamMap.get('hours_reservation')) ? this.aroute.snapshot.queryParamMap.get('hours_reservation') : '';
    this.queryString.name = (this.aroute.snapshot.queryParamMap.get('name')) ? encodeURIComponent(this.aroute.snapshot.queryParamMap.get('name')) : '';
    this.queryString.datetime = (this.aroute.snapshot.queryParamMap.get('datetime')) ? this.aroute.snapshot.queryParamMap.get('datetime') : this.filtercheck;
    this.queryString.datecalendar = (this.aroute.snapshot.queryParamMap.get('datecalendar')) ? this.aroute.snapshot.queryParamMap.get('datecalendar') : this.datecalendar;

    await this.mesasService.iniciando({ Authorization: '' }); // colocando token
    this.getResponse().subscribe(resp => {
      this.dataHist = resp.data.res;
      this.dataStat = resp.data.statistics;
      if (!this.dataHist.length) {
        this.statusInfo = true;
      }

      this.dataStat[0].num_people_all = this.fun_depie(this.dataStat[0].num_people_h, this.dataStat[0].num_people_m, this.dataStat[0].num_people_n, this.dataStat[0].num_people_all);
      this.queryString.reservation_id = '';
      this.applicationRef.tick();

    }, (error) => {
      console.log(error);
    });
  }

  async doRefresh(event) {
    this.getResponse().subscribe(resp => {
      this.dataHist = resp.data.res;
      this.dataStat = resp.data.statistics;
      if (!this.dataHist.length) {
        this.statusInfo = true;
      }

      this.dataStat[0].num_people_all = this.fun_depie(this.dataStat[0].num_people_h, this.dataStat[0].num_people_m, this.dataStat[0].num_people_n, this.dataStat[0].num_people_all);
      this.queryString.reservation_id = '';
      this.applicationRef.tick();
      event.target.complete();
    }, (error) => {
      console.log(error);
    })

  }

  async presentPopover(ev: any, data, type) {

    const popover = await this.popoverController.create({
      component: HistoriesPopoverInfoComponent,
      componentProps: {
        data,
        type
      },
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    return await popover.present();

  }

  getResponse() {

    this.statusInfo = false;
    return this.mesasService.getHistory(
      this.queryString.microsite,
      this.queryString.reservation_id,
      this.queryString.date_reservation,
      this.queryString.hours_reservation,
      this.queryString.name,
      this.queryString.datetime,
      this.queryString.datecalendar,
    );

  }

  fun_consume(data) {
    return (data) ? data : 0;
  }

  fun_fecha(data: Date) {
    return (data) ? moment(data).locale(lang[0]).format('dddd, D [de] MMM YYYY') : '';
  }

  fun_hora(data) {
    return (data) ? moment(data).locale(lang[0]).format('h:mm A') : '';
  }

  fun_depie(h, m, n, a) {
    return (a) ? (parseInt(a) - (parseInt(h) + parseInt(m) + parseInt(n))) : '0';
  }


  async cancelar(his, index) {
    const alertcancel = await this.alertController.create({
      header: 'Confirmar',
      message: '¿Estás seguro de cancelar la reserva?',
      buttons: [
        {
          text: 'No',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Si',
          handler: () => {
            this.cancelarApi(his, index);
          }
        }
      ]
    });

    await alertcancel.present();
  }

  cancelarApi(his, index) {
    this.helper.presentLoading({ message: 'Cancelando...' }); // loading
    this.mesasService.patchReservationHistoryCancel(his.ms_microsite_id, his.bs_user_id, his.id).subscribe(resp => {
      this.dataHist[index].res_reservation_status_id = '6';
      this.helper.dismissLoading(); // cerrar loading
      this.helper.presentAlertRedirect('Alerta', 'La reserva ha sido cancelada');
      console.log(resp);
    }, (error) => {
      this.helper.dismissLoading(); // cerrar loading
      console.log(error);
    });
  }
}
