import { Injectable } from '@angular/core';
import { Facebook, FacebookLoginResponse } from '@awesome-cordova-plugins/facebook/ngx';
import { ResponseLoginFacebook } from '../interfaces/facebook.interfaces';


const permissions = ['public_profile', 'email'];
const idSocialNetwork: number = 1;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(private fb: Facebook) { }

  loginFacebook() {

    this.fb.getLoginStatus().then(res => {
      console.log(res);
    }).catch(e => {
      console.log(e);
    });

    /*
    this.fb.logout().then(res => {
      console.log(" tttt22 ");
      console.log(res);
    }).catch(e => {
      console.log(" vvvv22 ");
      console.log(e);
    });
    */

    let promise = new Promise((resolve, reject) => {

      // verificar si existe una sesion anterior
      this.fb.getLoginStatus().then(res => {

        if (res.status == 'unknown') {
          // console.log(res.status);
          let datauser = this.getLogin();
          resolve(datauser);
        } else if (res.status == 'connected') {
          // console.log(res.status);
          this.fb.logout().then(logoutes => {
            // console.log(logoutes);
            let datauser = this.getLogin();
            resolve(datauser);
          });
        }


      })
        .catch(e => {
          console.log(e);
          reject(e);
        });


    });

    return promise;
  }

  getLogin() {

    let promise = new Promise((resolve, reject) => {

      this.fb.login(permissions)
        .then((res: FacebookLoginResponse) => {
          console.log("facebook response success");
          if (res.authResponse.accessToken) {
            let datauser = this.getProfileData(res.authResponse.userID, res.authResponse.accessToken).then(function (resolveOutput) {
              resolveOutput.verified = (resolveOutput.email) ? 1 : 0;
              resolveOutput.urlImage = `https://graph.facebook.com/${resolveOutput.id}/picture`;
              resolveOutput.token = res.authResponse.accessToken;
              resolveOutput.idSocialNetwork = idSocialNetwork;
              // console.log(resolveOutput);
              return resolveOutput;
            }, function (rejectOutput) {
              return rejectOutput;
            });
            resolve(datauser);
          } else {
            reject(res);
          }
          // console.log('Logged into Facebook1!', userrr);
        })
        .catch(e => {
          console.log('Error logging into Facebook', e);
          reject(e);
        });
      this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_CART);

    });

    return promise;
  }

  getProfileData(userId: string, token: string) {

    let promise = new Promise<ResponseLoginFacebook>((resolve, reject) => {
      this.fb.api('/me?fields=email,gender,name,first_name,last_name,picture', permissions)
        .then(user => {
          // user.birthdate = user.birthday;
          resolve(user);
        });
    });

    return promise;

  }


}
