import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MesasService } from '../../services/mesas.service';
import { RequestFormReservation } from '../../interfaces/form-reservation.interfaces';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { MicrositeService } from '../../services/microsite.service';
import { environment } from '../../../environments/environment';
import { LoadingController } from '@ionic/angular';

const lang = environment.lang;

@Component({
  selector: 'app-form-reserve',
  templateUrl: './form-reserve.component.html',
  styleUrls: ['./form-reserve.component.scss'],
})
export class FormReserveComponent implements OnInit {

  @Input() formdata: any;

  dataRequest: RequestFormReservation;

  headers: any;
  ms_microsite_id: string;
  countrySelect: any;
  gender: any;

  buttonLoading: boolean = false;

  user_msg: string = "";
  errform: any;
  loading: any;

  // tslint:disable-next-line: variable-name
  data_reserva = {
    date: null,
    hour: null,
    num_guest: null,
  }

  constructor(
    private aroute: ActivatedRoute,
    private mesasService: MesasService,
    private loginService: LoginService,
    private helper: GlobalFunctionsHelper,
    private router: Router,
    private micrositeService: MicrositeService,
    private loadingCtrl: LoadingController,
  ) { 
  }

  ngOnInit() {
    /*
    var datem = moment('2000-10-08').format("ZZ");
    var datetime = "2000-10-08T00:00:00" + datem;

    this.dataRequest = {
      note: null,
      conversion_id: null,
      guest: {},
      guest_list: [],
      userlogin_id: null
    }
    this.dataRequest.guest = {
      birthdate: datetime,
      first_name: '',
      last_name: '',
      phone: '',
      profession: null,
      country: '',
      gender: '',
      email: '',
    };

    this.headers = {
      appid: this.aroute.snapshot.queryParamMap.get('appid'),
      token: this.aroute.snapshot.params.token,
    }

    this.mesasService.iniciando(this.headers);
    this.countrySelect = this.mesasService.countrySelect();
    this.dataRequest.guest.country = this.countrySelect.country[0].cod;
    this.gender = this.loginService.genderSelect();
    this.dataRequest.guest.gender = this.gender[0].cod;
    this.loginService.getStorageLogin().then(resp => {
                        this.dataRequest.guest.email = resp.user.email;
                      });

    setTimeout(() => {
      this.paramsRoute().then(resp => {
              this.ms_microsite_id = resp;
              this.paramsRouteCreateReserve().then(resp1 => {
                this.mesasService.getReservationTemporal(this.ms_microsite_id, resp1, {}).subscribe( resp2 => {
                                    this.data_reserva = {
                                      // tslint:disable-next-line: max-line-length
                                      date: moment(`${resp2.data.reservation.date} ${resp2.data.reservation.hour}`).locale(lang[0]).format('dddd, D [de] MMM YYYY'),
                                      // tslint:disable-next-line: max-line-length
                                      hour: moment(`${resp2.data.reservation.date} ${resp2.data.reservation.hour}`).format('h:mm A'),
                                      num_guest: resp2.data.reservation.num_guest,
                                    }
                                    // console.log(this.data_reserva);
                                  }, error => {
                                    console.log(error);
                                  });
              });

      });
    }, 1000);
    */
  }

  // boton submit
  async onSubmitReserve(){
    await this.presentLoading('Creando reserva...');
    await this.loginService.verifySession(); // verificar sesion
    this.dataRequest.guest.birthdate = this.dataRequest.guest.birthdate.split("T")[0];
    this.dataRequest.guest.phone = this.dataRequest.guest.phone.toString();
    this.mesasService.postReservation(this.ms_microsite_id, this.dataRequest).subscribe( (resp) => {
                        this.loading.dismiss();
                        console.log(resp.data, this.headers.appid);
                        this.loginService.setStorateCreateReserve(resp.data);
                        // tslint:disable-next-line: max-line-length
                        this.router.navigateByUrl(`/home/${this.ms_microsite_id}/confirm/${this.headers.appid}`); // redirect
                        // console.log(resp);
                      }, error => {
                        this.loading.dismiss();
                        let urlredirect = null;
                        if (error.error.data && error.error.data.data == null){

                          this.errform = error.error.data.error;
                          this.user_msg = this.errform.user_msg;

                          let html = '';
                          for (var key in this.errform.errors) {
                              if (this.errform.errors.hasOwnProperty(key)) {
                                  this.errform.errors[key].forEach(function(e) {
                                    // tslint:disable-next-line: triple-equals
                                    if (key == 'guest.first_name') {
                                      html = html + `<div class="text-danger">
                                                      <strong>Nombre:</strong> ` + e +
                                                    `</div>`;
                                    }
                                    // tslint:disable-next-line: triple-equals
                                    if (key == 'guest.last_name') {
                                      html = html + `<div class="text-danger">
                                                      <strong>Apellido:</strong> ` + e +
                                                    `</div>`;
                                    }
                                    // tslint:disable-next-line: triple-equals
                                    if (key == 'guest.email') {
                                      html = html + `<div class="text-danger">
                                                      <strong>Email:</strong> ` + e +
                                                    `</div>`;
                                    }
                                    // tslint:disable-next-line: triple-equals
                                    if (key == 'guest.phone') {
                                      html = html + `<div class="text-danger">
                                                      <strong>Telefono movil:</strong> ` + e +
                                                    `</div>`;
                                    }
                                  });
                              }
                          }
                          this.user_msg = '<div class="text-danger">' + this.user_msg + '</div></br>' + html;

                        } else {
                          this.errform = error.error.error;
                          this.user_msg = this.errform.user_msg;
                          urlredirect = `/home/${this.ms_microsite_id}/search-availability`;
                        }

                        this.helper.presentAlertRedirect(
                          'Ops',
                          this.user_msg,
                          urlredirect
                          );

                      });
  }

  async paramsRouteCreateReserve() {
    return await this.aroute.snapshot.params.token;
  }

  async paramsRoute() {
    return await this.aroute.snapshot.params.ms_microsite_id;
  }

  async presentLoading(mensaje: string) {
    this.loading = await this.loadingCtrl.create({
      message: mensaje,
      // duration: 2000
    });
    return this.loading.present();
  }

}
