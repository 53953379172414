import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
// import { Data } from '../interfaces/login.interfaces';
import { LoadingController, AlertController } from '@ionic/angular';
// import { UserAgent } from '@ionic-native/user-agent/ngx';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import * as moment from 'moment';

// const apiAuth = environment.apiAuth;
const domainMain = environment.domainMain;
const url_image_favicon_160x160 = environment.url_image_favicon_160x160;

@Injectable({
    providedIn: 'root'
})
export class GlobalFunctionsHelper {

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private loadingCtrl: LoadingController,
        // private userAgent: UserAgent,
        private networkInterface: NetworkInterface,
        public alertController: AlertController,
    ) { }


    async presentLoading(data) {
        let loa = await this.loadingCtrl.create(data);
        return loa.present();
    }

    async dismissLoading() {
        // this.isLoading = false;
        return await this.loadingCtrl.dismiss(); // .then(() => console.log('dismissed'));
    }

    async userAgentRes() {
      /*
        return await this.userAgent.get()
            .then((res: any) => {
                return res;
            })
            .catch((error: any) => console.error(error));
            */
    }

    async networkInterfaceRes() {
        return await this.networkInterface.getWiFiIPAddress()
            .then(address => {
                return address.ip;
            })
            .catch(error => console.error(`Unable to get IP: ${error}`));
    }

    photoUser(image: string) {
        if (image) {
            return (image.includes('/login.png')) ? domainMain + image.replace('/75x75/', '/160x160/') : image;
        } else {
            return domainMain + '/archivo/img-foto/perfil/160x160/login.png';
        }
    }

    formatDateIniEndMonth(date: Date) {
        let date_ini = moment(date).startOf('month'); // .format('YYYY-MM-DD');
        let date_fin = moment(date).endOf('month'); // .format('YYYY-MM-DD');

        let date_ini_m = moment(date_ini).subtract(7, 'days').format('YYYY-MM-DD');
        let date_fin_m = moment(date_fin).add(14, 'days').format('YYYY-MM-DD');

        let datew = {
            date_ini: date_ini_m,
            date_fin: date_fin_m
        }

        return datew;
    }

    convertFechaToDate(date: string) {
        var datem = moment(date).format('ZZ');
        var datetime = date + 'T00:00:00' + datem;
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                if (date != null) {
                    var datetime = date.replace(/-/g, '/') + ' 00:00:00' + datem; // safari
                }
            }
        } else {
            var datetime = (date + " 00:00:00").replace(/-/g, '/');
        }

        var fecha = new Date(datetime);
        return fecha;
    };

    // reemplazar imagen favicon de sitio por una imagen defaulit
    imgFavicon160x160(img) {
        if (img) {
            return url_image_favicon_160x160 + img;
        } else {
            return '/assets/images/unknown1.jpg';
        }
    }



    async presentAlertRedirect(header: string, message: string, urlredirect?: string) {
        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        if (urlredirect) {
                            window.location.replace(urlredirect);
                        }
                    }
                }
            ]
        });

        await alert.present();
    }

    async presentAlertRedirectUpdate(header: string, message: string, urlredirect?: string, type: number = 0) {
        const buttonopt = [];
        if (!type) {
            buttonopt.push({
                text: 'Ahora no',
                // cssClass: 'cancel',
                handler: () => {
                    console.log('cancelar')
                }
            },
                {
                    text: 'Actualizar',
                    cssClass: 'dark',
                    handler: () => {
                        if (urlredirect) {
                            window.location.replace(urlredirect);
                        }
                    }
                }
            );
        } else {
            buttonopt.push({
                text: 'Actualizar',
                cssClass: 'dark',
                handler: () => {
                    if (urlredirect) {
                        window.location.replace(urlredirect);
                    }
                }
            }
            );
        }


        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            mode: 'ios',
            buttons: buttonopt,
        });

        await alert.present();
    }

}
