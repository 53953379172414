import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  { path: 'login-inicio', loadChildren: () => import('./pages/login-inicio/login-inicio.module').then( m => m.LoginInicioPageModule)},
      { path: 'login-inicio/:paramcustom', loadChildren: () => import('./pages/login-inicio/login-inicio.module').then( m => m.LoginInicioPageModule) },
  { path: 'home/:ms_microsite_id', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule), canActivate: [AuthGuardService]  },
      // tslint:disable-next-line: max-line-length
      { path: 'home/:ms_microsite_id/search-availability', loadChildren: () => import('./pages/search-availability/search-availability.module').then( m => m.SearchAvailabilityPageModule), canActivate: [AuthGuardService] },
      { path: 'home/:ms_microsite_id/search-availability-fast', loadChildren: () => import('./pages/search-availability-fast/search-availability-fast.module').then( m => m.SearchAvailabilityFastPageModule), canActivate: [AuthGuardService] },
      { path: 'home/:ms_microsite_id/reserve/:token', loadChildren: () => import('./pages/reserve/reserve.module').then( m => m.ReservePageModule), canActivate: [AuthGuardService] },
      { path: 'home/:ms_microsite_id/confirm/:appid', loadChildren: () => import('./pages/confirm/confirm.module').then( m => m.ConfirmPageModule), canActivate: [AuthGuardService] },
      /*
      { path: 'home/:ms_microsite_id/confirm/:create_reserve/:appid', loadChildren: './pages/confirm/confirm.module#ConfirmPageModule' },
      */
  { path: 'list', loadChildren: () => import('./pages/list/list.module').then( m => m.ListPageModule), canActivate: [AuthGuardService] },
  { path: 'list-micrositios', loadChildren: () => import('./pages/list-micrositios/list-micrositios.module').then( m => m.ListMicrositiosPageModule), canActivate: [AuthGuardService] },
  { path: 'history', loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule), canActivate: [AuthGuardService] },
  { path: 'notifications-push', loadChildren: () => import('./pages/notifications-push/notifications-push.module').then( m => m.NotificationsPushPageModule), canActivate: [AuthGuardService] },
  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule), canActivate: [AuthGuardService] },

  { path: 'rp/check-cod', loadChildren: () => import('./pages/rp-check-cod/rp-check-cod.module').then( m => m.RpCheckCodPageModule), canActivate: [AuthGuardService] },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
