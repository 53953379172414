import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { Data } from '../interfaces/login.interfaces';
import { ResponseApps } from '../interfaces/apps.interfaces';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { GlobalFunctionsHelper } from '../helpers/global-functions.helper';
import { Platform } from '@ionic/angular';

const url_image_favicon_160x160 = environment.url_image_favicon_160x160;
const url_image_logo_80x80 = environment.url_image_logo_80x80;
const apiAuth = environment.apiAuth;
const apiWebAdmin = environment.apiWebAdmin;
const APP_ID = environment.APP_ID;
const APP_IP_IOS = environment.APP_IP_IOS;
const BS_ROLE_ID_RP = environment.BS_ROLE_ID_RP;

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  // 'Authorization': "c8B6wbwadT3Bq6PmN8sURCDqEdzG6sEn"
});

@Injectable({
  providedIn: 'root'
})
export class MicrositeService {

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private appversion: AppVersion,
    private helper: GlobalFunctionsHelper,
    private platform: Platform
  ) { }

  // obtener informacion del app EJM: version del app
  getAppInfo(app_id: string, so: string, type: string) {
    // tslint:disable-next-line: max-line-length
    return this.http.get<ResponseApps>(`${apiWebAdmin}/apps/${app_id}/${so}/${type}`, { headers });
  }

  listMicro(data: Data) {
    let numR = [];
    data.manager.forEach(function (item) {

      if (item.bs_role_id === BS_ROLE_ID_RP) {
        numR.push({
          ms_microsite_id: item.ms_microsite_ms_mp.id,
          name: item.ms_microsite_ms_mp.name,
          image_logo: url_image_logo_80x80 + item.ms_microsite_ms_mp.image_logo,
          image_favicon: url_image_favicon_160x160 + item.ms_microsite_ms_mp.image_favicon,
          bs_role_id: item.bs_role.id,
          address: item.ms_microsite_ms_mp.address,
          redirectTo: (0) ? '/home/' + item.ms_microsite_ms_mp.id + '/search-availability-fast' : '/home/' + item.ms_microsite_ms_mp.id + '/search-availability',
          // verificar si el micrositio tiene permiso para reservas rapidas
        });
      }

    });
    // console.log(numR);
    return numR;
  }

  // obtener data de un micro especifico
  // tslint:disable-next-line: variable-name
  getDataMicro(ms_microsite_id: string, data: Data) {
    return this.listMicro(data).filter(item => item.ms_microsite_id == ms_microsite_id)[0];
  }

  async checkVersion(so: string) {
    if (this.platform.is('android')) {
      const version = await this.appversion.getVersionNumber();
      const dataApp = await this.getAppInfo(APP_ID, 'android', 'rp').toPromise().then();
      if (dataApp.last_version != version && dataApp.state == 1) {
        // tslint:disable-next-line: max-line-length
        this.helper.presentAlertRedirectUpdate('Actualiza la Aplicación', 'Actualiza la app de RP para que funcione más rápido y mejor', `https://play.google.com/store/apps/details?id=${dataApp.app_id}`, dataApp.priority_update);
      }
    } else if (this.platform.is('ios')) {
      const version = await this.appversion.getVersionNumber();
      const dataApp = await this.getAppInfo(APP_IP_IOS, 'ios', 'rp').toPromise().then();
      // para IOS
      if (dataApp.last_version != version && dataApp.state == 1) {
        // tslint:disable-next-line: max-line-length
        this.helper.presentAlertRedirectUpdate('Actualiza la Aplicación', 'Actualiza la app de RP para que funcione más rápido y mejor', `https://apps.apple.com/app/bookersnap-rp/id${dataApp.app_id}`, dataApp.priority_update);
      }
    }

  }

}
