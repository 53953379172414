import { Injectable } from '@angular/core';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';

const permissions = {
  'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
  // tslint:disable-next-line: max-line-length
  'webClientId': '855220309508-rl4cobs7hn8tlpicjgllia5vcpdqpetc.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
  // tslint:disable-next-line: max-line-length
  'offline': true // Optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
};

const idSocialNetworkG: number = 3;

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  constructor(
    private googlePlus: GooglePlus
  ) { }

  loginGoogle(){

    let promise = new Promise((resolve, reject) => {

      this.googlePlus.login(permissions)
        .then(user =>{

            let fullName = this.getNameParse(user);
            let datauser = {
              first_name: fullName.first_name,
              last_name: fullName.last_name,
              urlImage: user.imageUrl,
              email: user.email,
              idSocialNetwork: idSocialNetworkG,
              id: user.userId,
              token: JSON.stringify({
                access_token: user.accessToken,
              }),
              verified: (user.email) ? 1 : 0,
            }

            resolve(datauser);
        }, err =>{
            console.log('Error logging into Google', err);
            reject(err);
        });

    });

    return promise;

  }

  getNameParse(profile: any): any {
    let name = {
        first_name: '',
        last_name: ''
    }

    if (profile.hasOwnProperty('displayName')) {
        let fullName = profile.displayName.split(" ");

        name.first_name = fullName[0];
        name.last_name = fullName[1];
    } else {
        name.first_name = profile.familyName;
        name.last_name = profile.givenName;
    }

    return name;
}

}
