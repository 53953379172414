import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-reserve-zones',
  templateUrl: './reserve-zones.component.html',
  styleUrls: ['./reserve-zones.component.scss'],
})
export class ReserveZonesComponent implements OnInit {

  @Input() zones: any[] = [];
  @Input() checkdefault: any;
  @Output() selectZones = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onChangeHandler( $event: any ){

    const index = this.zones.findIndex(opt => opt.id === $event);
    this.checkdefault = {
      value: $event,
      string: this.zones[index].option,
      index
    };

    this.selectZones.emit(this.checkdefault);

  }

  salirSinArgumentos(){
    this.selectZones.emit({
          value: this.checkdefault.value,
          string: this.checkdefault.string,
          index: this.checkdefault.index
        });
  }

}
