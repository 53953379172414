import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { ResponseLogin } from '../interfaces/login.interfaces';
import { Router } from '@angular/router';
import { GlobalFunctionsHelper } from '../helpers/global-functions.helper';
import { ResponseDeviceUserid } from '../interfaces/device-userid.interfaces';
import { PushService } from './push.service';

const apiAuth = environment.apiAuth;
const API_AUTH_TOKEN = environment.API_AUTH_TOKEN;

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  Authorization: API_AUTH_TOKEN,
})

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loading: any;
  headerTemp: any;
  alert = true;

  public activePush: boolean = false;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private router: Router,
    private helper: GlobalFunctionsHelper,
  ) { }

  // iniciando headers
  async iniciando(param){
    param.Authorization = `Bearer ${(await this.getStorageLogin()).token_session}`;
    this.headerTemp = new HttpHeaders(param);
    return this.headerTemp;
  }

  setLoginBookersnap(params){
    return this.http.post<ResponseLogin>(`${apiAuth}/es/auth/bookersnap`, params);
  }

  setLoginSocial(params){
    return this.http.post<ResponseLogin>(`${apiAuth}/es/auth/socialnetwork`, params, {headers: headers});
  }

  postLogout(params){
    return this.http.post<any>(`${apiAuth}/es/auth/logout/apprp`, params, {headers: this.headerTemp});
  }

  postDeviceUserid(onesignalUserid: string){
    // tslint:disable-next-line: max-line-length
    return this.http.post<ResponseDeviceUserid>(`${apiAuth}/es/device/userid`, {onesignal_userid: onesignalUserid}, {headers: this.headerTemp});
  }


  setStorateLogin(data){
    this.storage.set('sessionlogin', data);
  }

  setStorateCreateReserve(data){
    this.storage.set('create_reserve', data);
  }

  /*
    Actualizar data del pais
  */
  updateStorateManagerLogin(roles: any) {
    this.storage.get('sessionlogin')
                      .then((res: any) => {
                        let value = res;
                        // Modify just that property
                        value.manager = roles;
                        this.storage.set('sessionlogin', value);

                      })
                      .catch((error: any) => console.error(error));
  }

  async closeSessiones(alert: boolean = true, onesignal_userid?: string){

    this.alert = alert;
    await this.iniciando({Authorization: ''});
    this.postLogout({onesignal_userid: onesignal_userid}).subscribe(resp => {
        this.storage.clear().then(() => {
            console.log('Todo los temporales eliminados');
            if(this.alert){
              this.alert = true;
              this.helper.presentAlertRedirect(
                'Ops',
                'Su sesión de usuario ha terminado, vuelva a iniciar sesión.',
                '/'
              );
            }
        });
    }, error => {
      console.log(error);
    });

  }

  getStorageCreateReserve(){
    return this.storage.get('create_reserve');
  }

  async getStorageLogin(){
    return await this.storage.get('sessionlogin')
                      .then((res: any) => {
                        return res;
                      })
                      .catch((error: any) => console.error(error));
  }

  async getStorageLoginNotificaciones(){
    return await this.storage.get('mensajes')
                      .then((res: any) => {
                        console.log(res);
                        return res;
                      })
                      .catch((error: any) => console.error(error));
  }

  async verifySession(){
    let session = await this.getStorageLogin();
    if(!session){
      await this.closeSessiones();
    }
  }

  genderSelect() {
    let genderSelect = [];
    genderSelect.push({
                      cod: 'M',
                      name: 'Hombre'
                    });
    genderSelect.push({
                      cod: 'F',
                      name: 'Mujer'
                    });
    genderSelect.push({
                      cod: 'O',
                      name: 'Otros'
                    });

    return genderSelect;
  }

  // activar / desactivar la variable de redirect
  activePageLoginPush(push: any) {
    if ( typeof push !== 'undefined' ) {
      return this.activePush = true;
    }
  }

  // redirect de pagina
  redirectPageLoginPush(){
    setTimeout(async () => {
      if (this.activePush) {
        this.activePush = false;
      } else {
        const sto = await this.getStorageLogin();
        if (!sto) {
          console.log('en login inicio');
          this.router.navigateByUrl(`/login-inicio`); //redirect
        } else {
          console.log('en lista de micrositios');
          this.router.navigateByUrl(`/list-micrositios`); //redirect
        }
      }
    }, 2500);
  }


}
