// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*
  apiAuth: 'http://apiauth.local.com/v1',
  apiMesas: 'http://apimesas.local.com/v1/es', // http://apimesas.bookersnap.com/v1/es
  apiWebAdmin: 'http://admin_web.local.com',  // conexion con el sistema de mesas, el widget
  apiRp: 'http://apirp.local.com/v1/es',
  domainMain: 'http://reservantro.com',

  lang: ['es', 'es-ES'],
  BS_ROLE_ID_RP: 3000,

  url_image_favicon_160x160: 'http://reservantro.com/archivo/img-iconmobil/160x160/',
  url_image_logo_80x80: 'http://reservantro.com/archivo/img-logo/80x80/',
  */
  apiAuth: 'https://apiauth.bookersnap.com/v1',
  apiMesas: 'https://apimesas.bookersnap.com/v1/es', // http://apimesas.bookersnap.com/v1/es
  apiWebAdmin: 'https://admin.bookersnap.com',  // conexion con el sistema de mesas, el widget
  apiRp: 'https://apirp.bookersnap.com/v1/es',
  domainMain: 'https://bookersnap.com',

  lang: ['es', 'es-ES'],

  BS_ROLE_ID_RP: 3000,

  url_image_favicon_160x160: 'https://bookersnap.com/archivo/img-favicon/80x80/',
  url_image_logo_80x80: 'https://bookersnap.com/archivo/img-logo/80x80/',

  calendar: { // calendar traducción
    month: {
      es: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      en: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
    },
    day: {
      es: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      en: ["S", "M", "T", "W", "T", "F", "S"]
    }
  },

  API_AUTH_TOKEN: 'c8B6wbwadT3Bq6PmN8sURCDqEdzG6sEn', // facebook
  APP_ID: 'com.bookersnap.reservacionesrp',
  APP_IP_IOS: '1479021999',
  SYSTEM: 'ios' // sistema operativo string 'android', 'ios'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
