import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { MesasService } from '../../services/mesas.service';
import { environment } from '../../../environments/environment';

import * as moment from 'moment';
import { CalendarComponent } from "ionic2-calendar/calendar";

//import { NgCalendarModule  } from 'ionic2-calendar';
import { ModalController } from '@ionic/angular';

const calendarMesesEs = environment.calendar.month.es;
const calendarDayEs = environment.calendar.day.es;
const lang = environment.lang;

@Component({
  selector: 'app-reserve-calendar',
  templateUrl: './reserve-calendar.component.html',
  styleUrls: ['./reserve-calendar.component.scss'],
})

export class ReserveCalendarComponent implements OnInit {

  @Input() daysDisabled: any[] = [];
  @Input() datehoy: string;
  @Input() msMicrositeId: string;
  @Input() reser: string = "";
  @Output() selectDate = new EventEmitter<any>();

  @ViewChild(CalendarComponent) myCalendar: CalendarComponent;

  nMonth: string; // numero del año-mes
  changeMonth = false;
  eventSource = [];

  calendar = {
    mode: 'month',
    currentDate: null,
    locale: 'es-AR'
  }
  viewTitle;
  startTime: any;
  endTime: any;

  prueba = false;
  item: boolean = true;
  iniCalendar: boolean;

  constructor(
    private mesasService: MesasService,
    private aroute: ActivatedRoute,
    private helper: GlobalFunctionsHelper,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.calendar.currentDate = this.helper.convertFechaToDate(this.datehoy); // new Date(`${this.datehoy}T00:00:00`);
    this.nMonth = moment(this.calendar.currentDate).format('YYYY-MM');
    this.iniCalendar = false; // no iniciar calendario
  }

  onCurrentDateChanged(date: any){

    if(this.iniCalendar) {
      let actual = moment(date).format('YYYY-MM');
      this.calendar.currentDate = date;

      if(this.nMonth !== actual && !this.changeMonth){
        this.nMonth = actual;
        this.changeMonth = true;
        let formatini = this.helper.formatDateIniEndMonth(date);
        this.item = false;
        this.mesasService.getDaysSisabled(this.msMicrositeId, formatini.date_ini, formatini.date_fin).subscribe( resp => {
                            this.daysDisabled = resp.data;
                            setTimeout(() => {this.item = true; } , 500);
                          }, error => {
                            console.log(error);
                          });
      } else if(this.changeMonth) {
        this.changeMonth = false;
      } else {
        const data = {
          value: moment(date).format('YYYY-MM-DD'),
          string: moment(date).locale(lang[0]).format('dddd, D [de] MMMM'),
        }

        // tslint:disable-next-line: triple-equals
        if(this.reser != 'fast') {
          this.selectDate.emit(data);
        } else {
          this.modalCtrl.dismiss(data);
        }
      }
    }

    this.iniCalendar = true; // iniciar calendario
  }

  //botton aplicar
  /*
  selectDateCalendar(){
    this.selectDate.emit({
      value: moment(this.calendar.currentDate).format('YYYY-MM-DD'),
      string: moment(this.calendar.currentDate).locale(lang[0]).format('dddd, D [de] MMMM'),
    });
  }
  */

  closeModal(){
    this.modalCtrl.dismiss();
  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  reloadSource(ini: any, fin: any){
    /*console.log("kkkkkkkkkkkkk");
    console.log(ini, fin);*/
  }

  onEventSelected(data: any){
    /*console.log("Aaaaaaaaaaaaa");
    console.log(data);*/
  }

  onTimeSelected(){
  }

  next(){
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slideNext();
  }

  back(){
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slidePrev();
  }

  today() {
    this.calendar.currentDate = new Date();
  }

  markDisabled = (date: Date) => {
    var dateTime = moment(date).format('YYYY-MM-DD');
    // console.log(dateTime);
    return (this.daysDisabled.indexOf(dateTime) !== -1);
  };

}
