import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private loginService: LoginService,
    private router: Router,
  ) { }

  async canActivate() {
    const session = await this.loginService.getStorageLogin();
    // console.log("hghghhg", session);
    if ( !session) {

      this.router.navigateByUrl(`/login-inicio`); // redirect
      return false;

    } else {
      if ( session.token_session != undefined && session.token_session != null) {
        return true;
      } else {
        this.router.navigateByUrl(`/login-inicio`); // redirect
        return false;
      }
    }

  }
}
