import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reserve-people',
  templateUrl: './reserve-people.component.html',
  styleUrls: ['./reserve-people.component.scss'],
})
export class ReservePeopleComponent implements OnInit {

  @Input() people: any[] = [];
  @Input() checkdefault: any;
  @Input() reser: string = "";
  @Output() selectPeople = new EventEmitter<any>();

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  onChangeHandler($event : any) {

    const index = this.people.findIndex(opt => opt.value === $event);
    this.checkdefault = {
      value: $event,
      string: this.people[index].text,
      index
    };
    // tslint:disable-next-line: triple-equals
    if (this.reser != 'fast') {
      this.selectPeople.emit(this.checkdefault);
    } else {
      this.modalCtrl.dismiss(this.checkdefault);
    }

  }

  salirSinArgumentos() {

    const data = this.checkdefault;
    // tslint:disable-next-line: triple-equals
    if (this.reser != 'fast') {
      this.selectPeople.emit(data);
    } else {
      this.modalCtrl.dismiss(data);
    }
  }

}
