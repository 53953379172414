import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { GlobalFunctionsHelper } from '../../helpers/global-functions.helper';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

import { MesasService } from '../../services/mesas.service';
import { ReservePeopleComponent } from '../reserve-people/reserve-people.component';
import { ReserveCalendarComponent } from '../reserve-calendar/reserve-calendar.component';
import { ReserveDateTimeComponent } from '../reserve-date-time/reserve-date-time.component';
import { ReserveMoreInfoComponent } from '../reserve-more-info/reserve-more-info.component';

const lang = environment.lang;

@Component({
  selector: 'app-form-reserve-fast',
  templateUrl: './form-reserve-fast.component.html',
  styleUrls: ['./form-reserve-fast.component.scss'],
})
export class FormReserveFastComponent implements OnInit {

  requestData: any;
  userreser: any[] = [
    {
      full_name: "",
    }
  ];
  // tslint:disable-next-line: variable-name
  ms_microsite_id: string;

  personasSeleccionada: any = {
    value: 0,
    string: '',
    index: 0,
  };

  fechaSeleccionada: any = {
    value: '',
    string: ''
  };

  horaSeleccionada: any = {
    value: '',
    string: '',
    index: 0,
  };

  constructor(
    private modalCtrl: ModalController,
    private mesasService: MesasService,
    private aroute: ActivatedRoute,
    private helper: GlobalFunctionsHelper,
    // private FormReserveFastCo
  ) { }

  async ngOnInit() {

    this.ms_microsite_id = await this.paramsRoute() as string;
    this.allData();

  }

  async allData(date?: string, type?: string){

    let data = this.mesasService.getFormatAvailability(this.ms_microsite_id, date).subscribe( resp => {
                      // console.log(resp);
                      if (type != 'calendar') {
                        // iniciando el calendario
                        this.fechaSeleccionada.value = resp.data.date;
                        // tslint:disable-next-line: max-line-length
                        this.fechaSeleccionada.string = moment(this.helper.convertFechaToDate(resp.data.date)).locale(lang[0]).format('D [de] MMM');// format('dddd, D [de] MMMM');

                        // iniciando el personas
                        this.personasSeleccionada.value = resp.data.people[1].value;
                        this.personasSeleccionada.string = resp.data.people[1].text;
                        this.personasSeleccionada.index = 1;

                        // iniciando el horas
                        this.horaSeleccionada.value = resp.data.hours[0].option;
                        this.horaSeleccionada.string = resp.data.hours[0].option_user;
                        this.horaSeleccionada.index = 0;

                        this.requestData = {

                          /*datos para el calendario*/
                          daysDisabled: resp.data.daysDisabled,
                          datehoy: this.fechaSeleccionada.value,
                          msMicrositeId: this.ms_microsite_id,

                          /*datos para personas*/
                          people: resp.data.people,
                          checkdefault: this.personasSeleccionada,

                          hours: resp.data.hours,
                          hourscheck: this.horaSeleccionada,
                        }

                      } else {
                        // iniciando el calendario
                        this.fechaSeleccionada.value = resp.data.date;
                        // tslint:disable-next-line: max-line-length
                        this.fechaSeleccionada.string = moment(this.helper.convertFechaToDate(resp.data.date)).locale(lang[0]).format('D [de] MMM');// format('dddd, D [de] MMMM');

                        // iniciando el horas
                        this.horaSeleccionada.value = resp.data.hours[0].option;
                        this.horaSeleccionada.string = resp.data.hours[0].option_user;
                        this.horaSeleccionada.index = 0;

                        this.requestData.hours = resp.data.hours;
                        this.requestData.hourscheck = this.horaSeleccionada;
                      }
                  }, (error) => {
                      // setTimeout(() => {this.loading.dismiss();}, 800);
                      console.log(error);
                  });
 }


  // abrir modal
  async abrirModal(componente: string){

    const requestDataFast = this.requestData;
    requestDataFast.reser = 'fast'; // tipo reservas rapidas

    const modal = await this.modalCtrl.create({
      // tslint:disable-next-line: triple-equals
      // tslint:disable-next-line: max-line-length
      component: (componente == 'people') ? ReservePeopleComponent : (componente == 'calendar') ? ReserveCalendarComponent : (componente == 'hours') ? ReserveDateTimeComponent : ReserveMoreInfoComponent,
      componentProps: requestDataFast,
      cssClass: 'li-ionic4-datePicker'
    });

    await modal.present();
    const responsemo1 = await modal.onDidDismiss();
    console.log(responsemo1);
    switch (componente) {
      case 'calendar':
        // tslint:disable-next-line: no-shadowed-variable
        if (responsemo1.data){
          if (responsemo1.data.value != this.requestData.datehoy) {
            // this.presentLoading('Espere un momento...');
            this.allData(responsemo1.data.value, 'calendar');
          } else {
            this.fechaSeleccionada = responsemo1.data;
            this.requestData.datehoy = this.fechaSeleccionada.value;
            // this.requestData.responsesearch.date = this.fechaSeleccionada.value;
          }
        }
        break;
      case 'people':
        if (responsemo1.data) {
          this.personasSeleccionada = responsemo1.data;
          this.requestData.checkdefault = this.personasSeleccionada;
          // this.requestData.responsesearch.num_guests = this.personasSeleccionada.value;
        }
        break;
      case 'hours':
        if (responsemo1.data){
          this.horaSeleccionada = responsemo1.data;
          this.requestData.hourscheck = this.horaSeleccionada;
          // this.requestData.responsesearch.hour = this.horaSeleccionada.value;
        }
        break;
      case 'zones':
        if  (responsemo1.data){
          // this.zonasSeleccionada = responsemo4.data;
          // this.requestData.zonescheck = this.zonasSeleccionada;
          // this.requestData.responsesearch.zone_id = this.zonasSeleccionada.value;
        }
        break;
      case 'moreinfo':
        if  (responsemo1.data){
          // this.zonasSeleccionada = responsemo4.data;
          // this.requestData.zonescheck = this.zonasSeleccionada;
          // this.requestData.responsesearch.zone_id = this.zonasSeleccionada.value;
        }
        break;
      case 'search-availability':
        // await this.loginService.verifySession(); // verificar sesion de usuario
        let responsemo5: any = await modal.onDidDismiss();
      default:
        break;
    }

  }

  async paramsRoute() {
    return await this.aroute.snapshot.params.ms_microsite_id;
  }

  addReser(){
    this.userreser.push({
      full_name: '',
    });
  }

  delReser(index: number) {
    this.userreser.splice(index, 1);
  }

}
