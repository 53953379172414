import { Injectable, EventEmitter } from '@angular/core';
// import { OneSignal, OSNotification, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
// import { OSNotification, OSNotificationPayload } from '@awesome-cordova-plugins/onesignal/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import { Storage } from '@ionic/storage';
import { LoginService } from './login.service';
import { GlobalFunctionsHelper } from '../helpers/global-functions.helper';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { WelcomePage } from '../pages/welcome/welcome.page';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  mensajes = [];
  onesignalUserid: string;
  pushListener = new EventEmitter<any>();
  deviceStateSubject: BehaviorSubject<any>;
  deviceState$: Observable<any>;

  constructor(
    // private oneSignal: OneSignal,
    private storage: Storage,
    private loginService: LoginService,
    private helper: GlobalFunctionsHelper,
    private router: Router,
    private navController: NavController,
  ) {

    this.deviceStateSubject = new BehaviorSubject<any>({});
    this.deviceState$ = this.deviceStateSubject.asObservable();
   }

  async getMessages() {
    await this.loaderMessages();
    return [...this.mensajes];
  }

  /**
   * Carga inicial de onesignal
   */
  configurateInit(){

    OneSignal.setAppId("3433eb6e-a179-4a9b-b99c-323cfda04471");
    OneSignal.setNotificationOpenedHandler((noti) => {
        this.loginService.activePageLoginPush(noti.notification);
        this.redirectNotifi(noti.notification);
    });

    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        // console.log("User accepted notifications: " + accepted);
    });

    // setear datos de dispositivo al cargar aplicacion
    OneSignal.getDeviceState((state) => {
      this.deviceStateSubject.next(state);
    });
  }

  /**
   * Obtener id de dispositivo
   * @returns string
   */
  getaOneSignalGetIds() {
    return this.deviceStateValue.userId;
  }

  /**
   * registrar device user id
   */
  async getDeviceUserIds(){

    const info = this.deviceStateValue;
    this.onesignalUserid = info.userId;

    if(typeof this.onesignalUserid !== 'undefined') {

      console.log(this.onesignalUserid, 'MI IDEUSER ONESIGNAL');
      await this.loginService.iniciando({Authorization: ''});
      this.loginService.postDeviceUserid(this.onesignalUserid).subscribe(resp => {
        console.log('registro de userid correctamente');
      }, error => {
        this.helper.presentAlertRedirect(
          'Ops',
          'Hubo problemas al iniciar sesión. Inténtelo nuevamente.',
          '/login-inicio'
        );
      });
    }

  }

  /**
   * Cargar mensajes para la notificacion
   * @returns Array
   */
  async loaderMessages(){
    this.mensajes = await this.storage.get('mensajes') || [];
    return this.mensajes;
  }

  /**
   * Redireccionar al dar clic en la notificacion push
   * @param noti
   */
  redirectNotifi(noti: any){
    const reservation_id = noti.additionalData.res_reservation_id;
    this.pushListener.emit('aprobado');
    this.navController.navigateRoot(`/history?microsite=all&reservation_id=${reservation_id}`);
  }

  /**
   * Sets device state
   */
  public set deviceState(data: any) {
    this.deviceStateSubject.next(data);
  }

  /**
   * Gets device state
   */
  public get deviceStateValue(): any {
    return this.deviceStateSubject.value;
  }


}
